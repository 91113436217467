body{
  background-color: #432d32 !important;
  color: white !important;
}
.custom-container {
  width: 80%;
  margin: 0px auto;
}

.menuBar {
  justify-content: space-evenly;
  text-wrap: nowrap;
}

.ad-section {
  background-color: #343a40; /* Darker shade of black */
}

.bg-success {
  background-color: #28a745 !important; /* Custom Green */
}

.btn-outline-light {
  border-color: #ffffff;
  color: #ffffff;
  background-color: transparent;
}

/* .desc {
  color: #ffffff; /* Ensures description is white */

.menuBar .imgClass {
  padding: 0.5rem;
}

.menuBar .linkText {
  background-color: #b9007c;
  padding: 0.5rem;
  margin: 1px;
  color: #fff;
}

.yellow-container {
  padding: 2rem 0rem;
}

.monthYrTbl {
  text-transform: uppercase;
}

#yearTableid .yellow-container .css-1s7v18u-control,
#yearTableid .yellow-container .react-select-option {
  background-color: #b9007c;
  color: #fff;
}

.menuBar .linkText:hover {
  border: 1px solid #b9007c;
  box-shadow: 6px 6px 5px #e07dc0;
  transition: 0.5s;
}

.refreshButton {
  background: #001fce;
  border: 1px solid #ffae00;
  padding: 10px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
}

.refreshButton:hover {
  border: 1px solid #ffae00;
  background-color: #fff;
  box-shadow: 6px 6px 5px #e49f09;
  transition: 0.5s;
}

.game_column {
  background: #22352a;
  border: 1px solid #000;
  padding: 1rem;
  color: #fff;
}

.game_column h6 {
  color: #ffff00;
}



/* =======antd table======= */
.ant-table-thead tr th {
  text-wrap: nowrap;
  background-color: red !important;
  border-radius: 0px !important;
  /* padding: 0.5em !important; */
  margin: 1px !important;
  border: 1px solid #000;
  color: #fff !important;
  text-align: center !important;
}


.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td .ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr:hover {
  background-color: #8b3d3d !important;
  color: red;

}

/* .ant-table-tbody.ant-table-row:hover {
  background-color: red !important;
} */

.ant-table-tbody td:nth-child(1) {
  text-wrap: nowrap;
  background-color: #096 !important;
  border-radius: 0px !important;
  margin: 0px !important;
  /* border: 1px solid #fff; */
  color: #fff !important;
  text-align: center;

}

.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td .ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
  border: 1px solid #000 !important;
  background-color: #036;
  color: #ff1f56;
}

.ant-table-row .ant-table-row-level-0:hover {
  background-color: red !important;
  color: #000 !important;
}


table {
  /* border-collapse: separate !important;
  border-spacing: 2px !important; */
}

.ant-table-wrapper .ant-table-cell {
  /* position: relative; */
  padding: 5px 1rem !important;
  /* overflow-wrap: break-word; */
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}

.tableStyle {
  /* border: 1px solid; */
}



/* all game button */
.allGameStyle {
  text-wrap: nowrap;
}




/* =====footer css===== */
#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #036;
  font-weight: bold;
  border-radius: 3px;
}

#go-button {
  background-color: #b9007c;
  color: #fff;
  cursor: pointer;
}

#go-button:hover {
  background-color: #fff;
  color: #b9007c;
  border: 1px solid #b9007c;
  box-shadow: 6px 6px 5px #e07dc0;
  transition: 0.5s;
}

/* =====table drop down style====== */
.css-b62m3t-container {
  /* border: 1px solid #036; */
  border-radius: 3px;
  /* color:#fff; */
}

/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #002d22;
  border-radius: 10px;

}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #002d22;
  /* border: 1px solid; */
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius: 10px;
  background-color: #156858;
}




/* ============privacy coockies===== */
.privacy {
  line-height: 2rem;
}

.section_1 {
  line-height: 3rem;
}

.privacy h1 {
  text-shadow: 2px 2px 3px #000;
  color: yellow;
  font-weight: 700;
}

.privacy h6,
.privacy span {
  color: #00ffff;
  font-weight: bold;
}

.privacy h3 {
  color: #ffae00 !important
}

.privacy label {
  color: #3d7e3d;
  font-weight: bold;
}

.coockie,
.privacy {
  background: #fff;
  color: #000;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0px -2px 10px #b9007c;
}

.coockie a,
.privacy a {
  text-decoration: none;
  color: #b9007c;
}

.footerLink {
  color: #b9007c;
  cursor: pointer;
}

/* ======= react toas ===== */
.toast-message {
  background: red !important;
  color: #fff !important;
  border: 5px solid #940505;
}

.Toastify__toast-body,
.toast-message button {
  color: #fff !important;
}

.Toastify__progress {
  background-color: green !important;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
  font-size: 26px;
}

/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}





/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #00ff4c;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  background-color: #c3ffde;
  /* Light cream background */
box-shadow: 4px 6px 10px rgb(206, 206, 206);
  border-radius: 90px;
}
.game-name, .game-result {
  text-align: center; /* Ensures text inside these elements is centered */
  margin: 0; /* Remove any extra margin */
  padding: 5px 0; /* Padding to provide some space */
}

.bg-dark {
  background-color: #343a40; /* Dark background for the container */
  padding: 10px; /* Padding around the content */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 10px; /* Add margin to separate each item */
}

.blinking-text {
  color: red; /* Example for blinking effect */
  animation: blink 1.5s infinite; /* Blinking animation */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Heading styles */
.advertisementSection h3 {
  color: #d35400;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #e74c3c;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}