/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 1024px) {
  body {
    background-color: #2c3119 !important;
    /* color: #fff !important */
  }

 

  .custom-container {
    width: 95% !important;
    margin: auto;
    padding-top: 1rem;
  }

  .banner {
    color: #fff;
  }

  .yellow-container {
    padding: 2rem 1rem !important;
  }

  .monthYrTbl {
    padding: 0.5rem;
    /* border: 1px solid #000; */
    background-color: #fff;
  }

  .banner .imgClass {
    background: #fff;
    margin: 15px 0px !important;
  }

  .imgClass {
    background: #fff;
    margin: 0rem -8px;
  }

  .menuBar .linkText:hover {
    border: 1px solid #bd0a85;
    box-shadow: 6px 6px 5px #c949a1;
    /* background-color: #e786c8; */
    transition: 0.5s;
    color: #fff;
  }

  .todayday {
    color: #fff;
  }

  .gamesBtn hr {
    opacity: 1;
    border-color: #fff;
  }

  .footer {
    background-color: #fff;
  }
}

@media screen and (max-width: 992px) {
  .custom-container {
    width: 95% !important;
    margin: auto;
    padding-top: 1rem;
  }

}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .custom-container {
    width: 90% !important;
    margin: auto;
    padding-top: 1rem;
  }

  .banner {
    color: #000;
  }

}

@media screen and (max-width: 768px) {
  body {
    background-color: transparent !important;
  }

  .banner {
    color: #000;
  }

}